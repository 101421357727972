<template>
    <div class="card mb-5 mb-xl-10 py-2 active-product-areas bank-follow-table">
        <div class="card-header px-0">
            <div
                class="card-title w-100 d-flex justify-content-between m-0 flex-column flex-md-row align-items-md-center">
                <h3 class="fw-bolder m-0 ps-3">
                    {{ $t("Active Product Areas") }}
                    <span class="count-label ms-2">{{ data.length }}</span>
                </h3>

                <div class="search-countries d-flex align-items-center" v-if="showInfoText">
                    <p class="ps-3 mb-0 me-7 info-section-text">
                        <a :href="`mailto:${platformConfigs.platformEmails.ContactEmail}`">
                            {{ $t("Contact PLATFORMNAME", {platformName: platformConfigs.platformName}) }}
                        </a>
                        {{ $t("to add more Product Areas") }}
                    </p>
                </div>
            </div>
        </div>

        <div class="table-wrapper">
            <el-table
                :data="data"
                :show-header="false"
                style="width: 100%">
                <el-table-column prop="name" class-name="bank-follow-table__name">
                    <template #default="scope">
                        <h1>{{ scope.row.name }}</h1>
                    </template>
                </el-table-column>
                <el-table-column prop="score" class-name="bank-follow-table__num_1" width="80">
                    <template #default="scope">
                        <div class="follow-status" :class="getStatusClass(scope.row.score)">
                            {{ scope.row.score }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="importance" class-name="bank-follow-table__num_2" width="80">
                    <template #default="scope">
                        <div class="follow-status">
                            {{ scope.row.importance }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getStatusClass } from "@/core/helpers/GlobalHelper";
import store from "@/store";

export default {
    name: "ActiveProductAreas",
    props: {
        data: Array,
        showInfoText: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            getStatusClass,
            value: ""
        };
    },
    computed: {
        platformConfigs() {
            return store.getters.platformConfigs;
        },
    }
};
</script>

<style lang="scss">
.info-section-text {
    background: rgba(167, 167, 167, 0.05);
    border-radius: 32px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    padding: 11px 17px;

    a {
        color: #435BF4;
    }
}

.bank-follow-table.active-product-areas {
    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 13px;
        color: #000000;
        opacity: 0.87;
        padding-left: 10px;
    }

    .follow-status {
        min-width: 52px;
        text-align: center;
    }
}
</style>
