<template>
    <div class="row g-5 g-xxl-8 mx-0 bank-follow-table">
        <ActiveProductAreas :data="bankDetails.activeProductAreas"/>

        <CountriesProductAreasTable
            :title="$t('Data on more Product Areas found')"
            :bg-image="'/media/buying/icons/wave-red.png'"
            :tableData="bankDetails.noLicenceProductAreas"
        />
    </div>
</template>

<script>
import ActiveProductAreas from "@/buying-teams/pages/bank/settings/bank/components/ActiveProductAreas";
import {BankSettingsContainer} from "@/store/models/bank/BankSettingsContainer";
import CountriesProductAreasTable from "@/buying-teams/pages/bank/settings/bank/components/CountriesProductAreasTable";

export default {
    name: "ProductAreas",
    props: {
        bankDetails: BankSettingsContainer
    },
    components: {
        CountriesProductAreasTable,
        ActiveProductAreas
    }
}
</script>
<style scoped lang="scss">
.info-section-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
}
</style>
